<template>
    <Overview 
        ref="Overview" 
        :moduleName="'Log'"
        :moduleNamePlural="'Logs'"
        :sort="'id'"
        :sortOrder="'-1'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :disableNewButton=true
        :disableEdit=false
        :disableDelete=true
    >
            <template #header >
                <FormItem type="checkbox" id="user_logs_only" :label="'User logs only'" v-model="useronly" :true-value="1" :false-value="0" @change="changeLogType(useronly)"/>
            </template>

        <template #columns>
            <Column header="Datum" field="created_at" :sortable="true">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.created_at, 'dateTime')}}
                </template>
            </Column>
            <Column field="user.email" header="Gebruiker" style=""></Column>
            <Column field="function" header="Actie" style=""></Column>
            <Column field="additional_information" header="Information"  style=""></Column>
        </template>

        <template #form>
            <LogForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import LogForm from '@/components/log/LogForm.vue';
import logService from '@/services/LogService';

export default {
    components: {
        Overview,
        Column,
        LogForm
    },
    data() {
        return {
            item: {},
            itemService: logService,
            itemFormRef: null,
            loading: false
        }
    },
    methods: {
        changeLogType(useronly){
            this.$refs.Overview.addCustomFilter("useronly", useronly)
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>
