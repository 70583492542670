import {ApiService} from '@/services/ApiService'

const URL = '/api/log'

class LogService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
}

export default new LogService()